.dev_header {
  height: 400px;
  width: 100%;
  background-image: url("../../assets/develop.jpg");
  background-size: cover;
  background-position: top;
  position: relative;
}

.dev_overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.567);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dev_overlay p:nth-child(1) {
  color: white;
  font-size: 2rem;
  font-weight: 500;
}
.dev_overlay p:nth-child(2) {
  color: white;
  width: 50%;
  line-height: 1.6;
  font-weight: 400;
}

@media (max-width: 756px) {
  .dev_overlay p:nth-child(1) {
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
  }
  .dev_overlay p:nth-child(2) {
    color: white;
    width: 100%;
    line-height: 1.6;
    font-weight: 400;
    padding: 0 1rem;
    font-size: 14px;
  }
  .dev_overlay {
    height: 320px;
  }
  .dev_header {
    height: 320px;
  }
}
