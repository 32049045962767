.project_category_main {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.6rem;
  /* box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.27); */
}

.project_category_image {
  width: 100%;
  height: 180px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  padding: 0.6rem;
}
.project_category_image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.project_category_items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.project_category_items span {
  font-size: 14px;
}

.project_category_items svg {
  color: rgb(77, 77, 77);
}

.project_category_content {
  display: flex;
  padding: 1rem 0;
  gap: 0.6rem;
}

.border_right {
  border-right: 1px solid rgb(214, 214, 214);
  padding-right: 0.6rem;
}

.project_category_item_payment::after {
  content: " (down payment)";
  color: orangered;
  font-size: 10px;
}
.project_category_item_area::after {
  content: " (area)";
  text-transform: capitalize;
  color: orangered;
  font-size: 10px;
}

.full_width {
  width: 100%;
}

.project_category_ammenties {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0;
  border-top: 1px solid #eaeaea;
}

.project_category_ammentt_item {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}
.project_category_ammentt_item svg {

  color: gray;
}
.project_category_ammentt_item span {
  font-size: 12px;
  color: rgb(24, 24, 24);
}
