.add_project_main {
  padding: 2rem;
}

.aadd_project_items {
  padding: 1rem;
}

.add_project_header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem;
}
