.single-client-testimonial {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    padding: 20px;
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .single-client-testimonial:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .client-image img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
  }
  
  .client-info {
    text-align: center;
  }
  
  .client-name {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
  }
  
  .client-title {
    font-size: 0.9rem;
    color: #777;
    margin-bottom: 5px;
  }
  
  .client-location {
    font-size: 0.8rem;
    color: #aaa;
  }
  
  .client-testimonial {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    margin-top: 10px;
  }
  