.listing_detail_main {
  padding-top: 4rem;
}

.listing_detail_container {
  max-width: 1400px;
  margin: 0 auto;
}

.listing_detail_galary_image {
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem;
  background-color: #f5f5f5;
}
.listing_detail_galary_image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.listing_detail_content {
  background-color: white;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.listing_detail_content_section {
  display: grid;
  grid-template-columns: 2.4fr 0.6fr;
  gap: 2rem;
}

.listing_detail_location,
.listing_detail_pricing {
  display: flex;
  align-items: center;
}
.listing_detail_location p {
  text-transform: capitalize;
  font-size: 14px;
  padding-left: 1rem;
}

.listing_detail_location span,
.listing_detail_pricing span {
  color: gray;
  font-size: 14px;
}

.listing_detail_price {
  color: orangered;
  font-size: 1.2rem;
  font-weight: 500;
  padding-left: 1rem;
}

.listing_detail_features {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e2;
  padding: 1rem 0;
}

.listing_detail_feature {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.4rem 1rem;
  border-radius: 6px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.2);
}

.listing_detail_feature_pair {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.listing_detail_feature_pair span,
.listing_detail_feature_pair svg {
  color: gray;
}

.listing_detail_feature_pair svg {
  font-size: 1.4rem;
}

.listing_detail_feature_count {
  color: orangered;
  border-left: 1px solid #ccc;
  padding-left: 0.6rem;
}

.listing_detail_detail {
  line-height: 1.6;
  color: rgb(63, 63, 63);
  font-size: 14px;
}

.listing_detail_section {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.listing_detail_section span {
  font-weight: 500;
}

.listing_detail_visual {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 2rem;
  background-color: white;
  margin: 1rem 0;
  border-radius: 6px;
}

.listing_detail_contact {
  background-color: white;
  padding: 1rem;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.listing_contact_actions {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 2rem 0;
  border-top: 1px solid #ccc;
}

@media (max-width: 756px) {
  .listing_detail_content_section {
    grid-template-columns: 1fr;
  }

  .listing_detail_feature_pair span {
    font-size: 12px;
  }
  .listing_detail_feature_pair svg {
    font-size: 1rem;
  }

  .listing_detail_feature_count {
    font-size: 12px;
  }

  .listing_detail_features {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 1rem;
  }
  .listing_detail_feature {
    justify-content: space-between;
  }

  .listing_detail_visual {
    grid-template-columns: 1fr;
  }
}
