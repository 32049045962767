.news_image_container {
  height: 400px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 6px;
}
.news_image_container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.single_news_main {
  background-color: white;
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 6px;
}

@media (max-width: 756px) {
  .news_image_container {
    height: 280px;
  }
}
