.chatbox_main {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1000;
}

.chat_box_container {
  width: 360px;
  height: 400px;
  border: 1px solid #ccc;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.2);
  background-color: #f5f5f5;
  overflow: hidden;
  position: relative;
  border-radius: 6px;
}

.chat_box_sender {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.chat_box_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem;
  border-bottom: 1px solid #ccc;
  flex-direction: row-reverse;
  background-color: #ccc;
}

.chat_box_header span {
  color: orangered;
}
.chat_chats {
  min-height: 74%;
  max-height: 74%;
  overflow-y: auto;
  width: 100%;
  padding: 1rem;
  padding-bottom: 4rem;
  background-color: white;
}

@media (max-width: 756px) {
  .chatbox_main {
    bottom: 0;
    right: 0;

  }
  .chat_box_container {
    width: 100vw;
  }
}
