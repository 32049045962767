.single_agent_main {
  padding: 1rem;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.single_agent_profile {
  height: 120px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.single_agent_profile img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
.single_agent_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
}
