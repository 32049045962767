.single_service_main {
  border: 1px solid #ccc;
  border-radius: 6px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
}
.single_service_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.656);
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  cursor: pointer;
  transition: all 0.4s;
  &:hover {
    backdrop-filter: blur(2px);
  }
}
.single_service_overlay:hover .service_title {
  font-size: 1.4rem;
}
.service_title {
  color: white;
  font-size: 1.1rem;
  font-weight: 500;
  text-transform: capitalize;
  transition: all 0.2s;
}

.service_image {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.service_image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
