.project_ammenty_main {
  padding: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  border: 1px solid #f5f5f5;
  border-radius: 6px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.2);
}

.project_ammenty_main:hover {
  background-color: orangered;
  color: white;
}

.project_ammenty_main span {
  font-size: 12px;
  text-transform: capitalize;
}
.project_ammenty_main svg {
  font-size: 1.2rem;
  color: gray;
}
