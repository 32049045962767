.single_project_main {
  background-color: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.3);
  margin-bottom: 2rem;
  width: 100%;
}

.single_project_high_lights {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.4rem;
  width: 100%;
}

.single_project_high_light {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  align-items: center;
  height: 200px;
  width: 100%;
}

.single_project_high_light svg {
  font-size: 2.6rem;
  color: #333;
  color: orangered;
}

.single_project_downpayment {
  font-size: 1.8rem;
  font-weight: 500;
}

.single_project_high_light span {
  font-size: 14px;
  color: gray;
  text-transform: capitalize;
  padding: 1rem 2rem;
  line-height: 1.6;
  display: inline-block;
}

.single_project_heading {
  font-size: 2rem;
  text-transform: capitalize;
  padding: 1rem 0;
  font-weight: 500;
}

.single_project_content,
.reverse {
  display: flex;
  width: 100%;
  gap: 1rem;
  padding: 2rem 0;
  overflow: hidden;
}
.single_project_video {
  flex: 1;
}

.reverse {
  flex-direction: row-reverse;
}
.single_project_elevation {
  background-color: #f5f5f5;
  padding: 2rem;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
}
.single_project_elevation img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.single_project_content_detail {
  padding: 1rem;
}

.single_project_categories {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 1rem;
}

.single_project_ammenties {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 1rem;
  /* grid-template-rows: repeat(auto-fill, minmax(32px, 1fr)); */
}

.project_features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding-top: 4rem;
}

@media (max-width: 756px) {
  .single_project_high_lights {
    grid-template-columns: 1fr;
  }

  .single_project_high_light {
    height: fit-content;
  }
  .single_project_heading {
    font-size: 1.2rem;
  }

  .single_project_content {
    flex-direction: column;
  }

  .project_features {
    grid-template-columns: 1fr;
  }
  .single_project_main {
    padding: 1rem;
  }
}
