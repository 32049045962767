.feature_projects_main {
  padding: 2rem 0;
  margin-top: 4rem;
}

.feature_projects {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  gap: 1rem;
}
