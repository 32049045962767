.review_list_main {
  /* padding: 2rem; */
  padding: 2rem 0;
  max-width: 1400px;
  margin: 0 auto;
}

@media (max-width: 756px) {
  .review_list_main {
    padding: 1rem;
  }
}
