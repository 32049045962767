.single-feature-project {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.single-feature-project:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.feature-project-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-bottom: 1px solid #e0e0e0;
}

.feature-project-content {
  padding: 20px;
}

.feature-project-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.feature-project-meta {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 15px;
}

.feature-project-meta span {
  display: flex;
  align-items: center;
  gap: 5px;
}

.feature-project-description {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
  margin-bottom: 20px;
}

.feature-project-button {
  display: inline-block;
  padding: 10px 15px;
  font-size: 0.9rem;
  font-weight: 600;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.feature-project-button:hover {
  background-color: #0056b3;
}
