.project_image_upoader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 120px;
  border: 1px dashed #ccc;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background-color: #f5f5f5;
    border-color: black;
  }
}
.project_image_upoader span {
  color: gray;
  font-size: 1.2rem;
}
.project_image_upoader svg {
  font-size: 2rem;
  color: gray;
}

.file_preview {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 1rem;
  overflow: hidden;
  padding: 1rem 0;
}

.file_preview_item {
  height: 80px;
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  position: relative;
}

.file_preview_delete {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.128);
}
.file_preview_delete svg {
  font-size: 2rem;
  background-color: white;
  color: black;
  border-radius: 50%;
  padding: 0.2rem;
  cursor: pointer;
  display: none;
}
.file_preview_delete:hover svg {
  display: block;
}
.file_preview_item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.loading-text {
  font-size: 14px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
