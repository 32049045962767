.single-process-step {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 250px;
  text-align: center;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.single-process-step:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.process-step-image img {
  width: 100%;
  height: 100px;
  object-fit: contain;
  margin-bottom: 15px;
}

.process-step-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.process-step-description {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
}
