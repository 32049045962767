.single_branch_main {
  overflow: hidden;
  padding: 1rem;
}

.single_branch_image {
  height: 200px;
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 6px;
}
.single_branch_overlay {
  position: absolute;
  z-index: 10;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.157);
  display: flex;
  align-items: end;
  justify-content: flex-start;
  padding-bottom: 1rem;
  padding-left: 1rem;
}
.single_branch_overlay span {
  color: white;
  text-transform: capitalize;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.694);
  display: inline-block;
  padding: 0.2rem 0.4rem;
  border-radius: 6px;
}

.single_branch_image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.single_branch_content {
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
@media (max-width: 756px) {
  .single_branch_content {
    grid-template-columns: 1fr;
  }
}
