.add_prject_category_main {
  padding: 1rem;
}

.add_category_actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}
