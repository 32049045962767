.news_main {
  max-width: 1400px;
  margin: 0 auto;
  margin-top: 2rem;
  padding: 2rem 1rem;
}

.news_header {
  background-image: url("../../assets/news.jpg");
  height: 400px;
  width: 100%;
  background-size: cover;
  background-position: top;
  position: relative;
}

.news_overlay {
  position: absolute;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.667);
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.6rem;
}

.news_overlay p:nth-child(1) {
  color: white;
  font-size: 2rem;
  font-weight: 500;
}
.news_overlay p:nth-child(2) {
  color: white;
  width: 50%;
  margin: 0 auto;
}

@media (max-width: 756px) {
  .news_overlay p:nth-child(2) {
    width: 100%;
    padding: 0 1rem;
  }

  .news_main {
    padding: 2rem 0;
  }

  .news_header {
    height: 300px;
  }
  .news_overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-top: 2rem;
  }
}
