.our-process {
    background-color: #f9f9f9;
    padding: 50px 20px;
    text-align: center;
  }
  
  .section-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 30px;
  }
  
  .process-steps-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  