.sidebar_main {
  background-color: #333;
  max-height: 100vh;
  overflow-y: auto;
  height: 100vh;
  position: fixed;
  width: 340px;
  overflow-y: auto;
}

.admin_sidebar_container {
  padding: 1rem;
}

.admin_sidebar_item,
.menu_item_fill {
  width: 100%;
  padding: 0.8rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  color: white;
  border-right: 2px solid transparent;
  border-radius: 6px;
  transition: all 0.2s;
  overflow: hidden;
  border-bottom: 1px solid rgb(89, 89, 89);
  &:hover {
    background-color: #4a4a4a;
    border-right-color: orangered;
  }
}

.menu_item_fill {
  background-color: #4a4a4a;
  border-right-color: orangered;
}
.admin_sidebar_item svg {
  font-size: 1.2rem;
}

.menu_item_fill svg {
  font-size: 1.2rem;
}
