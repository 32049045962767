.project_list_main {
  padding: 0 4rem;
}

.project_list {
  padding: 2rem;
  border-radius: 6px;
}
.project_load_action {
  text-align: right;
}

@media (max-width: 756px) {
  .project_list_main {
    padding: 0;
  }
  .project_list {
    padding: 0;
  }
  .project_load_action {
    padding-bottom: 2rem;
    text-align: center;
  }
}
