.listing_main {
  background-color: white;
  padding: 2rem 4rem;
}

@media (max-width: 756px) {
  .listing_main {
    padding: 2rem 1rem;
  }
}
