.single_partner_main {
  height: 120px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 0.8rem;
}

.single_partner_main img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
