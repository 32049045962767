.header_main {
  position: fixed;
  width: 100%;
  z-index: 100;
}

.header_container {
  background-color: rgba(0, 0, 0, 0.601);
  backdrop-filter: blur(10px);
  padding: 0.8rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_menus {
  gap: 2rem;
}

.header_menus a {
  color: white;
  font-weight: 600;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 0.2s;
  &:hover {
    color: orangered;
    transition: all 0.2s;
  }
}

.header_mobile {
  padding: 0.4rem;
  background-color: #ebebeb;
  display: none;
}

.header_menu_icon {
  font-size: 1.8rem;
  border: 1px solid #333;
  border-radius: 4px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.3);
}

.mobile_header_items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile_header_menus_open,
.mobile_header_menus_close {
  position: fixed;
  inset: 0;
  z-index: 100;
  width: 100%;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  transform: translateX(0);
  transition: transform 0.2s;
}

.mobile_header_menus_close {
  transform: translateX(-100%);
  transition: transform 0.2s;
}

.mobile_header_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f0f0f0;
  padding: 0.8rem;
}

.mobile_header_links {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  padding: 2rem 0;
  text-transform: capitalize;
  font-weight: 500;
}
.mobile_header_links a {
  text-decoration: none;
  color: black;
  border-bottom: 1px solid #ccc;
  padding-left: 1rem;
}

.small_logo_header {
  width: 52px;
}

.header_logo {
  width: 82px;
}

@media (max-width: 756px) {
  .header_container {
    display: none;
  }
  .header_mobile {
    display: block;
    padding: 0.8rem;
  }
}
