.branch_page_main_section {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 4rem;
}

.branch_page_banner {
  background-image: url("../../../assets/branch_banner.jpg");
  height: 400px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-position: center;
}
.branch_page_overlay {
  inset: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.532);
  z-index: 10;
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.branch_page_overlay p:nth-child(1) {
  font-size: 2rem;
  font-weight: 500;
  color: white;
  text-transform: capitalize;
}
.branch_page_overlay p:nth-child(2) {
  color: white;
  width: 50%;
  line-height: 1.6;
}

@media (max-width: 756px) {
  .branch_page_overlay{
    gap: 0;
  justify-content: flex-end;
  }
  .branch_page_overlay p:nth-child(1) {
    font-size: 1.4rem;
    padding: 0 1rem;
  }
  .branch_page_overlay p:nth-child(2) {
    width: 100%;
    font-family: 14px;
    padding: 0 1rem;
  }

  .branch_page_banner {
    height: 320px;
  }
}
