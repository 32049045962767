.cominig_soon_main {
  background-image: url("../../../assets/coming.jpg");
  width: 100%;
  min-height: 400px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}
.coming_soon_overlay {
  position: absolute;
  inset: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.628);
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: center;
}

.coming_soong_image {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.569);
  border-radius: 12px;
}
.coming_soong_image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.coming_soon_content {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.coming_soon_heading {
  text-transform: capitalize;
  font-size: 2rem;
  font-weight: 500;
  color: white;
}

.coming_soon_detail {
  width: 80%;
  color: white;
  line-height: 1.6;
}

@media (max-width: 756px) {
  .coming_soon_heading {
    font-size: 1rem;
  }

  .coming_soon_overlay {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .coming_soon_detail {
    width: 100%;
    font-size: 14px;
  }

  .coming_soon_content {
    gap: 0;
    padding: 0.6rem 1rem;
  }

  .cominig_soon_main {
    height: 600px;
  }
}
