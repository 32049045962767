.why_us_main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 1rem 2rem;
  margin-top: 4rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.908);
}

.why_us_image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 6px;
}
.why_us_image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.content_title {
  color: white;
  font-weight: 500;
  font-size: 1.6em;
  width: 100%;
  display: inline-block;
  text-align: center;
  letter-spacing: 1px;
}

.cntent_items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 1rem;
}

.why_us_cntent_title {
  color: white;
  font-weight: 500;
  letter-spacing: 0.6px;
}
.why_us_content_detail {
  font-size: 14px;
  color: rgb(223, 223, 223);
  font-weight: 400;
}

.content_item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #979797;
  gap: 0.4rem;
}

.why_us_content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 756px) {
  .why_us_main {
    padding: 1rem;
    grid-template-columns: 1fr;
  }
}
