.home_slider_main {
  background-color: rgba(0, 0, 0, 0.592);
  height: 100vh;
  width: 100%;
  position: relative;
}

.home_slider_image {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.home_slider_image img {
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.home_slider_overlay {
  background-color: rgba(0, 0, 0, 0.599);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home_slider_overlay h2 {
  color: white;
  font-size: 4rem;
}

.home_slider_overlay p {
  color: rgb(215, 215, 215);
  font-size: 1.2rem;
  font-weight: 500;
  padding-bottom: 1rem;
  border-bottom: 1px dashed white;
}

.home_hero_actions {
  padding: 1rem 0;
}

@media (max-width: 756px) {
  .home_slider_overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    padding-top: 2rem;
  }
  .home_slider_overlay h2 {
    font-size: 26px;
  }
  .home_slider_main {
    height: 60vh;
  }

  .home_slider_image {
    height: 60vh;
  }
}
