.single_listing_image {
  height: 140px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  padding: 0.4rem;
  position: relative;
}

.single_listing_overlay {
  background-color: rgba(0, 0, 0, 0.262);
  position: absolute;
  z-index: 10;
  inset: 0;
  width: 100%;
  height: 100%;
}

.single_listing_image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.single_listing_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}

.single_listing_container {
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 1rem;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.2);
}
.single_listing_features {
  border-top: 1px solid #ededed;
  padding-top: 0.6rem;
}

.single_listing_feature_item {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}
.single_listing_feature_item svg {
  color: gray;
  font-size: 1.4rem;
}
.single_listing_feature_item span {
  font-size: 14px;
}
