.single_review_main {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  border-radius: 6px;

}

.single_review_user {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.review_user_name {
  width: 2rem;
  height: 2rem;
  font-size: 14px;
  border-radius: 50%;
  background-color: orangered;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  outline: 1px solid #ccc;
  outline-offset: 2px;
}

.single_review_date {
  font-size: 14px;
  color: gray;
}
