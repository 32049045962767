.admin_chats_user {
  background-color: white;
  border-radius: 6px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.2);
  padding: 0.6rem;
  margin-bottom: 0.6rem;
  display: flex;
  cursor: pointer;
  transition: background-color 0.2s ease;
  justify-content: space-between;
  &:hover {
    background-color: #f5f5f5;
  }
}

.admin_chats_user_id {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding-bottom: 0.6rem;
}

.admin_chats_user_id span:nth-child(1) {
  color: gray;
  font-size: 14px;
}

span[name="online"] {
  color: seagreen;
  text-transform: capitalize;
  font-size: 14px;
}

span[name="offline"] {
  color: red;
  text-transform: capitalize;
  font-size: 14px;
}

.admin_chats_chats {
  background-color: white;
  padding: 1rem;
  position: relative;
  height: 90vh;
  overflow-y: auto;
  padding-bottom: 4rem;
  padding-top: 6rem;
}

.admin_chats_single_message {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  background-color: #f5f5f5;
  width: fit-content;
  padding: 0.6rem;
  margin-bottom: 1rem;
  border-radius: 12px;
}

.admin_chats_single_message span:nth-child(2) {
  color: gray;
  font-size: 14px;
}

.float_right {
  display: flex;
  justify-content: flex-end;
}

.admin_chats_messages_container {
  position: relative;
}

.admin_chats_sender {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 0 1rem;
}

.admin_chats_message_header {
  padding: 1rem;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  position: fixed;
  z-index: 100;
  width: 100%;
}

.admin_chats_users {
  width: 100%;
  padding: 2rem 0;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
}
