.footer-section {
  background-color: #1976d2;
  color: white;
  padding: 40px 20px;
}

.footer-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.footer-column h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.footer-column p {
  font-size: 1rem;
  line-height: 1.6;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li a,
.footer-column ul li .MuiLink-root {
  font-size: 1rem;
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-column ul li a:hover,
.footer-column ul li .MuiLink-root:hover {
  color: #f4f9ff;
}

.social-icons a {
  color: white;
  margin-right: 10px;
  transition: color 0.3s ease, transform 0.2s ease;
}

.social-icons a:hover {
  color: #f4f9ff;
  transform: translateY(-3px);
}

.footer-bottom {
  text-align: center;
  font-size: 0.9rem;
  border-top: 1px solid #f4f9ff;
  padding-top: 10px;
}

.footer-bottom p {
  margin: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-column h3 {
    font-size: 1.3rem;
  }

  .footer-column p {
    font-size: 0.9rem;
  }

  .social-icons a {
    font-size: 1.2rem;
  }
}
