.about_ceo_container {
  background-color: white;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.about_banner {
  background-image: url("../../assets/about.jpg");
  height: 400px;
  width: 100%;
  background-position: top;
  background-size: cover;
  position: relative;
}
.about_overlay {
  background-color: rgba(0, 0, 0, 0.471);
  inset: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  backdrop-filter: blur(4px);
  gap: 1rem;
}
.about_overlay p:nth-child(1) {
  font-size: 2rem;
  color: white;
  font-weight: 500;
}
.about_banner p:nth-child(2) {
  color: white;
  width: 50%;
  line-height: 1.6;
  background-color: rgba(0, 0, 0, 0.665);
  padding: 1rem;
  border-radius: 6px;
}

.about_ceo_section {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.about_ceo_image {
  height: 330px;
  width: 100%;
  overflow: hidden;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about_ceo_image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.about_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.about_heading {
  font-size: 1.4rem;
  font-weight: 500;
  font-style: italic;
}

.about_established {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid #f5f5f5;
}
.about_established span:nth-child(1) {
  color: gray;
}
.about_established span:nth-child(2) {
  color: orangered;
  font-size: 1.2rem;
}

.about_detail {
  padding: 1rem 0;
  line-height: 1.6;
}

.about_video {
  max-width: 100%;
  margin: 0 auto;
}

@media (max-width: 756px) {
  .about_banner {
    height: 400px;
  }
  .about_banner p:nth-child(2) {
    width: 100%;
    font-size: 14px;
  }

  .about_ceo_container {
    padding: 1rem;
  }
  .about_detail {
    padding: 0;
  }
  .about_ceo_section {
    grid-template-columns: 1fr;
  }
}
