.branch_list_main {
  padding: 4rem;
}

.branch_list_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2rem;
  background-color: white;
  padding: 1rem;
  border-radius: 6px;
}

@media (max-width: 756px) {
  .branch_list_main {
    padding: 2rem 1rem;
  }
}
